@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.custom-rating svg {
  color: #ffc30b;
}

@media (min-width: 1024px){
  .big-image{
    height:900px;
  }
}

/* .prev-button,
.next-button {
 
  appearance: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: transparent;
  cursor: pointer;

 
  svg {
    fill: #333;
    transition: fill 0.2s ease-in-out;
  }

 
  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
    svg {
      fill: #fff;
    }
  }
} */

.input-container {
  position: relative;
}

.input-label {
  position: absolute;
  top: 50%;
  left: 0.75rem;
  pointer-events: none;
  transform: translateY(-50%);
  transition: all 0.2s ease-in-out;
  background-color: #fff;
  padding: 0 0.25rem;
  color: #a0aec0; /* placeholder color */
}

.input-field:focus + .input-label,
.input-field:not(:placeholder-shown) + .input-label {
  top: -0.5rem;
  left: 0.75rem;
  transform: translateY(0) scale(0.75);
  color: #4a5568; /* label color when active */
}

.react-multi-carousel-list {
  touch-action: pan-y; /* Allow vertical panning for scrolling */
}

.react-multi-carousel-item {
  transition: transform 300ms ease-in-out;  
}

